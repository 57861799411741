import { Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { MdOutlineStar } from "react-icons/md";
import {
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineTwitter,
} from "react-icons/ai";

function ReviewCard(props) {
  const FiveStars = () => {
    return (
      <Flex>
        {Array.from(Array(5), (e, i) => {
          return (
            <div key={i}>
              <MdOutlineStar size={24} />
            </div>
          );
        })}
      </Flex>
    );
  };
  const Socials =
    props.icon === "instagram"
      ? AiOutlineInstagram
      : props.icon === "facebook"
      ? AiOutlineFacebook
      : AiOutlineTwitter;
  return (
    <VStack
      justifyContent={"center"}
      alignItems={"center"}
      border="1px solid"
      borderColor="#c78f00"
      background={"transparent"}
      color="#fff"
      p={5}
      mx={2}
      spacing={5}
    >
      <FiveStars />
      <Text fontWeight={"bold"} fontSize={20}>
        {props.title}
      </Text>
      <Text textAlign={"center"}>{props.text}</Text>
      <Socials size={40} />
      <Text>
        {props.author} from {props.icon}
      </Text>
    </VStack>
  );
}

export default ReviewCard;
