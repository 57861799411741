import { Flex, Text } from "@chakra-ui/react";
import React from "react";

function HeadingTitle({ text }) {
  return (
    <Flex w={"100%"} justifyContent="center">
      <Text
        as={"h2"}
        bgGradient="linear(to-l, #ffecc9, white, primary.300)"
        data-aos="zoom-in"
        bgClip="text"
      >
        {text}
      </Text>
    </Flex>
  );
}

export default HeadingTitle;
