import { Flex, HStack } from "@chakra-ui/react";
import React from "react";
import PortraitCard from "../cards/PortraitCard";
import ButtonPrimary from "../ButtonPrimary";
import HeadingTitle from "../common/HeadingTitle";
import { topIllustration } from "../../mixin/data";

function Portraits() {
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      w={"100%"}
      py={{ base: 0, md: 10, lg: 20 }}
    >
      <HeadingTitle text="Cartoon portraits" />
      <Flex
        w={"100%"}
        mb={{ base: 4, md: 12 }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {topIllustration.map((image, index) => {
          return (
            <PortraitCard
              key={index}
              imageName={image.imageName}
              title={image.title}
              folderName="portraits"
              size={"30%"}
            />
          );
        })}
      </Flex>
      <HStack spacing={6}>
        <ButtonPrimary
          text={"get yours now"}
          variant={"solid"}
          link={"/order-portrait"}
          size={"lg"}
        />
        <ButtonPrimary
          text={"gallery"}
          variant={"outline"}
          link={"/gallery"}
          size={"lg"}
        />
      </HStack>
    </Flex>
  );
}

export default Portraits;
