import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-vertical-timeline-component/style.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "../src/theme";
import { BrowserRouter as Router } from "react-router-dom";
import "aos/dist/aos.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Router basename="/">
        <App />
      </Router>
    </ChakraProvider>
  </React.StrictMode>
);
