import { Box, Flex, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import ButtonPrimary from "../ButtonPrimary";
import { RiLandscapeFill, RiAlarmFill } from "react-icons/ri";
import { FaFile } from "react-icons/fa";
import { FaUser, FaUserFriends, FaUserPlus } from "react-icons/fa";

function PricingCard({ imageName, price, link, icon, name, time, index }) {
  const CustomIcons =
    icon === "FaUser"
      ? FaUser
      : icon === "FaUserFriends"
      ? FaUserFriends
      : FaUserPlus;
  return (
    <VStack width={"100%"} mb={{ base: 8, md: 0 }}>
      <Flex
        flexDirection={"column"}
        alignItems="center"
        padding={"1px"}
        width="90%"
        data-aos="flip-right"
        data-aos-delay={index === 0 ? "0" : index === 1 ? "300" : "600"}
        shadow={"md"}
        borderRadius={"5px"}
        borderColor={"primary.200"}
        borderWidth={"1px"}
        borderStyle={"solid"}
        py={4}
        px={5}
        pos="relative"
      >
        <Box w={"100%"}>
          <Text
            fontSize={22}
            fontWeight={"bold"}
            mb={4}
            py={3}
            bg={"primary.100"}
            textAlign={"center"}
            borderRadius={3}
          >
            {price}
          </Text>
        </Box>
        <Image borderRadius={"5px"} src={`./images/portraits/${imageName}`} />
        <Box py={6}>
          <ButtonPrimary
            variant="outline"
            size="lg"
            text="Get started"
            link={link}
          />
        </Box>
        <VStack
          spacing={4}
          alignItems="start"
          justifyContent={"start"}
          textAlign="left"
          w={"100%"}
          fontWeight={"bold"}
        >
          <Flex alignItems={"center"}>
            <CustomIcons />
            <Text pl={3}>{name}</Text>
          </Flex>
          <Flex alignItems={"center"}>
            <RiLandscapeFill />
            <Text pl={3}>Uniform / Custom background (+10€)</Text>
          </Flex>
          <Flex alignItems={"center"}>
            <FaFile />
            <Text pl={3}>
              JPEG format |{" "}
              {index !== 2 ? "1080 x 1350 pixels" : "Custom resolution"}
            </Text>
          </Flex>
          <Flex alignItems={"center"}>
            <RiAlarmFill />
            <Text pl={3}>{time}</Text>
          </Flex>
        </VStack>
      </Flex>
    </VStack>
  );
}

export default PricingCard;
