import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import Slider from "react-slick";
import ButtonPrimary from "../components/ButtonPrimary";
import PricingCard from "../components/cards/PricingCard";
import ReviewCard from "../components/cards/ReviewCard";
import HeadingTitle from "../components/common/HeadingTitle";
import Timeline from "../components/Timeline";
import { reviews, pricingCard } from "../mixin/data";

function OrderPortrait() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  };
  const description =
    "Would you like to have a personalized cartoon portrait ? Do you want to offer an original gift to your friends, families or even colleagues ? It's this way ! All drawings are made by using a professional tablet. You won't be disappointed !";
  return (
    <Box mt={{ base: 10, lg: "headerTop.5" }}>
      <HeadingTitle text="Choose a plan" />
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        w={"100%"}
        mb={16}
        mt={{ base: 0, md: 8 }}
        justifyContent={"space-between"}
        alignItems={"start"}
      >
        {pricingCard.map((card, index) => {
          return (
            <PricingCard
              key={index}
              imageName={card.imageName}
              price={card.price}
              link={card.link}
              name={card.info.main.name}
              icon={card.info.main.icon}
              time={card.time}
              index={index}
            />
          );
        })}
      </Flex>
      <Text
        textAlign={"center"}
        fontSize={{ base: "18px", lg: "24px" }}
        mb={20}
      >
        {description}
      </Text>
      <Timeline />
      <HStack
        spacing={5}
        w={"100%"}
        justifyContent="center"
        alignItems={"center"}
        mt={20}
        mb={32}
      >
        <ButtonPrimary
          text="Choose a plan"
          link="#portrait-plan"
          variant="solid"
        />
        <ButtonPrimary
          text="portraits gallery"
          link="/gallery"
          variant="outline"
        />
      </HStack>
      <Slider {...settings}>
        {reviews.map((review, index) => {
          return (
            <ReviewCard
              key={index}
              title={review.title}
              text={review.text}
              icon={review.icon}
              author={review.author}
            />
          );
        })}
      </Slider>
    </Box>
  );
}

export default OrderPortrait;
