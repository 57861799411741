import { Box, Flex, Image, Link, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import HeadingTitle from "../common/HeadingTitle";

function Biography() {
  return (
    <Box
      h={{ base: "100%", md: "50vh", lg: "100vh" }}
      pt={{ base: 0, lg: 20, "2xl": 32 }}
      pb={{ base: 6, lg: 20 }}
      // bgImage={"/images/404/daftpunk_3.png"}
      // bgRepeat={"no-repeat"}
      width="100%"
      // backgroundSize={"contain"}
      // backgroundPosition={"left"}
      // backgroundColor="#2C303C"
      className="biography"
      my={{ base: 0, lg: 20 }}
    >
      <Flex
        flexDirection={{ base: "column", md: "row-reverse" }}
        alignItems={"center"}
        justifyContent="space-between"
        w="100%"
        h={{ base: "100%", md: "50vh", lg: "100vh" }}
        // backdropFilter="auto"
        // backdropBlur="40px"
        m={0}
      >
        <Image
          id="biography"
          src="/images/portraits/freddynovationSH.png"
          w={{ base: "100%", md: "40%", lg: "40%" }}
          borderRadius={5}
          data-aos="fade-left"
        />
        <VStack
          px={{ base: 4, md: "", lg: 32 }}
          data-aos="fade-right"
          fontSize={{ base: 16, md: "", lg: 24 }}
          w={{ base: "100%", md: "60%", lg: "60%" }}
        >
          <HeadingTitle text="Who am I" />
          <Box textAlign={"left"}>
            <Text as="div" display={{ base: "none", md: "flex" }} pb={10}>
              Hey, welcome ! I'm freddynovation and yes, i'm the guy on the
              right...
            </Text>
            <Text as="div" display={{ base: "flex", md: "none" }} pb={10}>
              Hey, welcome ! I'm freddynovation and yes, i'm the guy above...
            </Text>
            <Text pb={10}>
              Passionate about video and drawing since I was kid, I love sharing
              my creations on the web. I worked on several 3D projects in which
              I had to learn modeling, animation and texturing. You can find all
              these projects on this{" "}
              <span>
                <Link as={NavLink} to={"/projects"} color="primary.100">
                  page.
                </Link>
              </span>
            </Text>
            <Text pb={10}>
              {" "}
              Few years ago, I started to learn vector drawing and more
              precisely the cartoon portraits. Since then I haven't stopped.
              Interested with a cartoon portrait ? It's{" "}
              <span>
                <Link as={NavLink} to={"/order-portrait"} color="primary.100">
                  this way.
                </Link>
              </span>
            </Text>
            <Text>Have fun !</Text>
          </Box>
        </VStack>
      </Flex>
    </Box>
  );
}

export default Biography;
