import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import News from "../components/projects/News";
import HorizontalVideo from "../components/homepage/HorizontalVideo";
import ButtonPrimary from "../components/ButtonPrimary";

function Projects() {
  return (
    <VStack spacing={0} pt={{ base: 20, md: 0 }}>
      <News order={1} title="Latest projects" />
      <News order={2} title="Most viewed" />
      <HorizontalVideo
        title="Stranger Things: The Mind Flayer"
        videoName={"Stranger Things"}
        previewImage="preview_stranger_things.webp"
        url="https://youtu.be/r36uXZIaQ0o"
      />
      <HorizontalVideo
        title="Jumana"
        videoName={"jumana"}
        previewImage="preview_jumana.webp"
        url="https://youtu.be/LCxQ9jIHiQQ"
      />
      <HorizontalVideo
        title="Hartania: The queen of monsters"
        videoName={"hartania"}
        previewImage="preview_hartania.webp"
        url="https://youtu.be/r8PaNVOXN6s"
      />
      <Box mt={10}>
        <ButtonPrimary
          variant="solid"
          text="more on youtube"
          link="https://www.youtube.com/freddyNovation"
          isExternal={true}
        />
      </Box>
    </VStack>
  );
}

export default Projects;
