import { Box } from "@chakra-ui/react";
import React from "react";
import Mosaic from "../components/mosaic/Mosaic";

function Gallery() {
  return (
    <Box mt={{ base: 10, lg: "headerTop.5" }}>
      <Mosaic />
    </Box>
  );
}

export default Gallery;
