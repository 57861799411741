import { Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import ButtonPrimary from "../components/ButtonPrimary";

function PageNotFound() {
  return (
    <VStack
      spacing={2}
      alignItems={"center"}
      height={"100vh"}
      justifyContent={"center"}
      bgImage={"/images/404/image.webp"}
      bgRepeat={"no-repeat"}
      width="100%"
      backgroundSize={"100%"}
      position="absolute"
      top={0}
      left={0}
      filter="auto"
    >
      <Heading
        as={"div"}
        fontSize={{ base: 100, md: 200, lg: 300 }}
        className="page-not-found"
      >
        404
      </Heading>
      <Text pb={10} fontSize={24} color="white">
        Sorry this page does not exist
      </Text>
      <ButtonPrimary text="go back home" link="/" variant="solid" />
    </VStack>
  );
}

export default PageNotFound;
