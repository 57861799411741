import { Box, Flex, Image, Link, Heading, Button } from "@chakra-ui/react";
import React from "react";

function HomeHeader() {
  return (
    <Box pos={"relative"} w={"100%"} height={{ base: "auto", "2xl": "100vh" }}>
      <Image src={`./images/others/freddynovation_header_name.gif`} w="100%" />
      <Box
        pos={{ base: "relative", xl: "absolute" }}
        top={{ base: "auto", xl: "40%", "2xl": "50%" }}
        zIndex={9000}
        right={{ base: "auto", xl: "5%", "2xl": "12%" }}
        px={{ base: 20, md: 20, lg: 20, xl: 0 }}
        pt={{ base: 20, md: 20, lg: 20, xl: 0 }}
        pb={{ base: 0, md: 20, lg: 20, xl: 0 }}
      >
        <Flex flexDirection={"column"} alignItems={"center"}>
          <Heading
            as={"h1"}
            fontSize={{ base: "32px", md: "44px", lg: "64px" }}
            textTransform={"uppercase"}
            fontWeight={"bold"}
            bgGradient="linear(to-l, #ffecc9, white, primary.300)"
            bgClip="text"
            data-aos="zoom-in"
          >
            Welcome !
          </Heading>
          <Heading
            as={"h1"}
            fontSize={{ base: "14px", md: "16px", lg: "24px" }}
            py={4}
            maxW={"450px"}
            textAlign="center"
            fontWeight={"normal"}
          >
            3D Animations, VFX & Portraits
          </Heading>
          <Link href="#biography">
            <Button
              bg={"primary.100"}
              _hover={{ bg: "primary.200" }}
              size={{ base: "sm", lg: "md" }}
              display={{ base: "none", md: "flex" }}
            >
              DISCOVER
            </Button>
          </Link>
        </Flex>
      </Box>
    </Box>
  );
}

export default HomeHeader;
