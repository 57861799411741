import { Box, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import photos from "./Photos";
import HeadingTitle from "../common/HeadingTitle";
import { useHistory } from "react-router-dom";

const slides = photos.map(({ src, width, height, images }) => ({
  src,
  width,
  height,
  srcSet: images.map((image) => ({
    src: image.src,
    width: image.width,
    height: image.height,
  })),
}));
function Mosaic() {
  const [index, setIndex] = useState(-1);
  let history = useHistory();

  return (
    <Box pt={10}>
      <Flex alignItems={"center"} onClick={history.goBack} cursor="pointer">
        <BsArrowLeft fontSize={40} color={"#eaa800"} cursor="pointer" />
        <Text
          pl={4}
          color="primary.100"
          _hover={{ textDecoration: "underline" }}
        >
          BACK
        </Text>
      </Flex>
      <HeadingTitle text="Portraits gallery" />
      <PhotoAlbum
        layout="columns"
        photos={photos}
        onClick={(event, photo, index) => setIndex(index)}
      />
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Thumbnails]}
      />
    </Box>
  );
}

export default Mosaic;
