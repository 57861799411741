import React, { useEffect } from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import ButtonPrimary from "../ButtonPrimary";
import { useLocation } from "react-router-dom";

function HeaderScrollAnimation() {
  const location = useLocation();

  const html = document.documentElement;
  const loadAnim = () => {
    const canvas = document.querySelector(".hero-scrolling");
    const context = canvas.getContext("2d");
    const frameCount = 44;
    const currentFrame = (index) =>
      `./images/header_animation/oxly_stand_${index
        .toString()
        .padStart(4, "0")}.jpg`;

    const preloadImages = () => {
      for (let i = 1; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
      }
    };

    canvas.width = 1920;
    canvas.height = 1080;

    const img = new Image();
    img.src = currentFrame(0);
    img.onload = function () {
      context.drawImage(img, 0, 0);
    };
    const updateImage = (index) => {
      img.src = currentFrame(index);
      context.drawImage(img, 0, 0);
    };

    window.addEventListener("scroll", () => {
      const scrollTop = html.scrollTop;
      const maxScrollTop = html.scrollHeight - window.innerHeight;
      const scrollFraction = scrollTop / maxScrollTop;
      const frameIndex = Math.min(
        frameCount - 1,
        Math.floor(scrollFraction * frameCount)
      );

      requestAnimationFrame(() => updateImage(frameIndex + 1));
    });
    preloadImages();
  };

  useEffect(() => {
    location.pathname === "/nfts" && loadAnim();
  }, []);
  return (
    <Box pos={"relative"}>
      <Box
        pos={{ base: "relative", md: "absolute" }}
        top={{ md: "7%", xl: "15%" }}
        zIndex={9000}
        right={{ md: "5%", xl: "12%" }}
      >
        <Flex flexDirection={"column"} alignItems={"center"}>
          <Heading
            as={"h1"}
            fontSize={{ base: "18px", md: "44px", lg: "64px" }}
            textTransform={"uppercase"}
            fontWeight={"bold"}
            bgGradient="linear(to-l, #ffecc9, white, primary.300)"
            bgClip="text"
            data-aos="zoom-in"
            zIndex={2}
          >
            {/* Oxly Punk */}
          </Heading>
          <Heading
            as={"h5"}
            fontSize={{ base: "12px", md: "16px", lg: "24px" }}
            py={4}
            maxW={"450px"}
            textAlign="center"
            fontWeight={"normal"}
          >
            {/* 10 000 unique Oxly Punk NFTs living on the Elrond blockchain */}
            Collection is coming soon..
          </Heading>
          <Box mb={4} display={{ base: "none", xl: "flex" }}>
            <ButtonPrimary
              variant={"solid"}
              text="Join the community"
              link={"/nfts"}
              disabled={true}
            />
          </Box>
        </Flex>
      </Box>
      <Flex
        justifyContent={"end"}
        alignItems={"center"}
        alignContent={"center"}
        zIndex={9000}
        pos={"absolute"}
        top="80%"
        right={"10%"}
        width={"100%"}
        mt={0}
      >
        <Heading
          as={"h1"}
          fontSize={{ base: "14px", md: "24px", lg: "42px" }}
          bgGradient="linear(to-l, #ffecc9, white, primary.300)"
          bgClip="text"
          data-aos="flip-right"
        >
          Hey, welcome.
        </Heading>
      </Flex>
      <Box
        height={["30vh", "50vh", "300vh"]}
        position={"relative"}
        className="canvas-wrapper"
        onContextMenu={(e) => {
          e.preventDefault();
          return false;
        }}
      >
        <Box
          as="canvas"
          position={"sticky"}
          top={"0%"}
          left={"0%"}
          height={"100%"}
          width={"100vw"}
          className="hero-scrolling"
          maxH={{ base: "50vh", md: "50vh", lg: "80vh", xl: "120vh" }}
          maxW={"100vw"}
          mt={0}
        />
      </Box>
    </Box>
  );
}

export default HeaderScrollAnimation;
