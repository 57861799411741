import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import HeadingTitle from "../common/HeadingTitle";
import { latestProjects } from "../../mixin/data";
import PortraitCard from "../cards/PortraitCard";
import ReactPlayer from "react-player";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ButtonPrimary from "../ButtonPrimary";

function News({ order, title }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [item, setItem] = useState(null);

  const openModal = (item) => {
    onOpen();
    setItem(item);
  };

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      w={"100%"}
      py={{ base: 0, md: 5, lg: 20 }}
    >
      <HeadingTitle text={title} />
      <Flex
        w={"100%"}
        flexDir={{ base: "column", md: "row" }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {latestProjects.map((item, index) => {
          return (
            item.order === order && (
              <Box
                w={{ base: "100%", md: "30%" }}
                pb={{ base: 4, lg: 0 }}
                onClick={() => openModal(item)}
                key={index}
              >
                <PortraitCard
                  imageName={item.thumbnail}
                  title={item.title}
                  folderName="news"
                  type={"news"}
                  link={"/projects/" + item.slug}
                />
              </Box>
            )
          );
        })}
      </Flex>
      {order === 3 && (
        <Box mt={{ base: 4, md: 12 }}>
          <ButtonPrimary
            text={"watch more"}
            variant={"solid"}
            link={"/projects"}
            size={"lg"}
          />
        </Box>
      )}
      {item && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
          <ModalOverlay />
          <ModalContent background={"body.main"} pt={10}>
            {/* <ModalHeader textAlign={"center"}>{item.title}</ModalHeader> */}
            <ModalCloseButton />
            <ModalBody>
              {/* <Flex
                justifyContent={"space-between"}
                w="100%"
                alignItems={"center"}
              > */}
              <Box className="wrapper" pos="relative" w="100%" pb={4}>
                <ReactPlayer
                  width={"100%"}
                  height={"70vh"}
                  playing={true}
                  loop={true}
                  url={[`../videos/home/${item.videoName}.mp4`]}
                  controls
                />
              </Box>
              {/* <Flex flexDir={"column"}>
                  <Text>{item.title}</Text>
                  <Text>{item.description}</Text>
                </Flex> */}
              {/* </Flex> */}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
}

export default News;
