export const reviews = [
  {
    title: "Quality",
    text: "This guy is so talented, i recommend him !",
    icon: "instagram",
    author: "Kyle",
  },
  {
    title: "Nice work",
    text: "I ordered a cartoon portrait and in few hours I got it. I love his drawing style",
    icon: "instagram",
    author: "Bob",
  },
  {
    title: "I love it !",
    text: "Freddynovation is fast and I am so satisfied with the result. Don't hesitate and go ahead",
    icon: "twitter",
    author: "MHM",
  },
  {
    title: "Perfection",
    text: "Love the result, all the details are sick",
    icon: "instagram",
    author: "Bruno",
  },
  {
    title: "The best !",
    text: "I ordered few portraits from him and I was never disapointed",
    icon: "facebook",
    author: "Ali",
  },
];

export const portraits_slider = [
  { image: "drake.webp", type: "solo" },
  { image: "lennavivas.webp", type: "solo" },
  { image: "ritika.webp", type: "solo" },
  { image: "mattdoesfitness.webp", type: "solo" },
  { image: "yedess.png", type: "solo" },
  { image: "fastgoodcuisine.webp", type: "solo" },
  { image: "bernard_couple.webp", type: "couple" },
  { image: "gavin_fam.webp", type: "couple" },
  { image: "vic_swetha.webp", type: "couple" },
  { image: "sam.webp", type: "couple" },
  { image: "steven_rapin.webp", type: "couple" },
  { image: "bernard_fam.webp", type: "custom" },
  { image: "jess_fam.webp", type: "custom" },
  { image: "fsprod_fam.webp", type: "custom" },
  { image: "psg.webp", type: "custom" },
];

export const topIllustration = [
  {
    imageName: "zendaya.webp",
    title: "Zendaya",
  },
  {
    imageName: "fastgoodcuisine.webp",
    title: "FastGoodCuisine",
  },
  {
    imageName: "nivethaPethuraj.webp",
    title: "Nivetha",
  },
];

export const pricingCard = [
  {
    imageName: "drake_bestseller.webp",
    price: "35€",
    link: "/order-portrait/form?type=solo",
    info: {
      main: { name: "Solo portrait", icon: "FaUser" },
    },
    time: "24hrs to 48hrs",
  },
  {
    imageName: "bernard_couple.webp",
    price: "65€",
    link: "/order-portrait/form?type=couple",
    info: {
      main: { name: "Couple portrait (2 people)", icon: "FaUserFriends" },
    },
    time: "48hrs to 72hrs",
  },
  {
    imageName: "psg_1.webp",
    price: "+90€",
    link: "/order-portrait/form?type=custom",
    info: {
      main: {
        name: "Custom portrait (more than 2 people)",
        icon: "FaUserPlus",
      },
    },
    time: "1 week",
  },
];

// VERTICAL VIDEOS PROJECTS
export const latestProjects = [
  {
    slug: "baymax-chill",
    thumbnail: "glass_baymax.webp",
    title: "Baymax & chill",
    videoName: "baymax",
    description: "",
    date: "February 2021",
    images: [
      "glass_baymax.webp",
      "astronaut.webp",
      "harry_potter_hogwarts.webp",
    ],
    order: 1,
  },
  {
    slug: "hogwarts-balcony",
    thumbnail: "harry_potter_hogwarts.webp",
    title: "Hogwarts balcony",
    videoName: "HP",
    description: "",
    date: "January 2021",
    images: [
      "harry_potter_hogwarts.webp",
      "astronaut.webp",
      "glass_baymax.webp",
    ],
    order: 1,
  },
  {
    slug: "astronaut-moon",
    thumbnail: "astronaut.webp",
    title: "Just dance on the moon",
    videoName: "astronaut_moon",
    description: "",
    date: "March 2022",
    images: [
      "astronaut.webp",
      "glass_baymax.webp",
      "harry_potter_hogwarts.webp",
    ],
    order: 1,
  },
  {
    slug: "king-kong",
    thumbnail: "kong.webp",
    title: "King kong attacks in Paris",
    videoName: "kong",
    description: "",
    date: "March 2022",
    images: [
      "astronaut.webp",
      "glass_baymax.webp",
      "harry_potter_hogwarts.webp",
    ],
    order: 2,
  },
  {
    slug: "spiderman-poster",
    thumbnail: "spiderman.webp",
    title: "Spiderman: No way home motion poster",
    videoName: "spiderman_motion_poster",
    description: "",
    date: "March 2022",
    images: [
      "astronaut.webp",
      "glass_baymax.webp",
      "harry_potter_hogwarts.webp",
    ],
    order: 2,
  },
  {
    slug: "thor_hammer",
    thumbnail: "thor_hammer.webp",
    title: "Thor's Hammer",
    videoName: "thor_hammer",
    description: "",
    date: "March 2022",
    images: [
      "astronaut.webp",
      "glass_baymax.webp",
      "harry_potter_hogwarts.webp",
    ],
    order: 2,
  },
  {
    slug: "eiffel_tower",
    thumbnail: "eiffel_tower.webp",
    title: "Let me be free",
    videoName: "eiffel_tower",
    description: "",
    date: "March 2022",
    images: [
      "astronaut.webp",
      "glass_baymax.webp",
      "harry_potter_hogwarts.webp",
    ],
    order: 3,
  },
  {
    slug: "halo",
    thumbnail: "halo.webp",
    title: "Halo in real life",
    videoName: "halo",
    description: "",
    date: "March 2022",
    images: [
      "astronaut.webp",
      "glass_baymax.webp",
      "harry_potter_hogwarts.webp",
    ],
    order: 3,
  },
  {
    slug: "donuts",
    thumbnail: "donuts.webp",
    title: "It's raining donuts",
    videoName: "donuts_falling",
    description: "",
    date: "March 2022",
    images: [
      "astronaut.webp",
      "glass_baymax.webp",
      "harry_potter_hogwarts.webp",
    ],
    order: 3,
  },
];
