import { Box, Container, Flex, Text, VStack } from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { Redirect, useLocation } from "react-router-dom";
import HeadingTitle from "../components/common/HeadingTitle";
import * as Yup from "yup";
import TextField from "../components/form/TextField";
import axios from "axios";
import { BsCheckCircleFill } from "react-icons/bs";
import { BiInfoCircle } from "react-icons/bi";
import { portraits_slider } from "../mixin/data";
import PortraitCard from "../components/cards/PortraitCard";

import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import { useRef } from "react";
import ButtonPrimary from "../components/ButtonPrimary";
import { Tooltip } from "@chakra-ui/react";
import Slider from "react-slick";

function FormOrder() {
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type");
  const [numPerson, setNumPerson] = useState(3);
  const [orderId, setOrderId] = useState("");
  const [ref, setRef] = useState(null);
  const formRef = useRef();
  const [approved, setApproved] = useState(false);
  const [amount, setAmount] = useState(
    type === "solo" ? 35 : type === "couple" ? 65 : 30 * numPerson
  );
  // const [amount, setAmount] = useState(0.01);
  const currency = "EUR";
  const style = { layout: "vertical" };

  if (type !== "solo" && type !== "couple" && type !== "custom") {
    return <Redirect to={"/page-not-found"} />;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: type !== "custom" ? 4 : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          centerMode: true,
        },
      },
    ],
  };

  const ButtonWrapper = ({ currency, showSpinner }) => {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
      setRef(formRef);
      // dispatch({
      //   type: "resetOptions",
      //   value: {
      //     ...options,
      //     currency: currency,
      //   },
      // });
    }, []);
    return (
      <Box w={"100%"}>
        {showSpinner && isPending && <div className="spinner" />}
        <PayPalButtons
          style={style}
          disabled={
            (ref &&
              ref.current.values.firstName &&
              ref.current.values.lastName &&
              ref.current.values.email &&
              ref.current.values.image &&
              ref.current.isValid) ||
            (ref &&
              ref.current.values.firstName &&
              ref.current.values.lastName &&
              ref.current.values.email &&
              ref.current.values.image &&
              ref.current.values.customBackground &&
              ref.current.values.customBgInfo &&
              ref.current.isValid)
              ? false
              : true
          }
          forceReRender={[amount, currency, style]}
          fundingSource={undefined}
          createOrder={(data, actions) => {
            return actions.order
              .create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: currency,
                      value: amount,
                    },
                  },
                ],
              })
              .then((orderId) => {
                return orderId;
              });
          }}
          onApprove={async function (data, actions) {
            if (formRef.current) {
              formRef.current.handleSubmit();
              if (formRef.current.isValid) {
                return actions.order.capture().then((details) => {
                  const formData = new FormData();
                  formData.append("lastName", formRef.current.values.lastName);
                  formData.append(
                    "firstName",
                    formRef.current.values.firstName
                  );
                  formData.append("email", formRef.current.values.email);
                  formData.append(
                    "image",
                    formRef.current.values.image,
                    formRef.current.values.image.name
                  );
                  formData.append(
                    "customBgInfo",
                    formRef.current.values.customBgInfo
                  );
                  formData.append("type", type);
                  formData.append("orderID", data.orderID);
                  axios
                    .post(
                      "https://freddynovation-api.freddynovation.com/default.php",
                      formData
                    )
                    .then((response) => {
                      console.log(response);
                      axios.post(
                        "https://freddynovation-api.freddynovation.com/email.php",
                        formData
                      );
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  setApproved(true);
                  setOrderId(data.orderID);
                  formRef.current.resetForm();
                });
              }
            }
          }}
        />
      </Box>
    );
  };

  return (
    <Box
      mt={{ base: 10, lg: "headerTop.5" }}
      bgImage={"/images/404/fairytale.webp"}
      bgRepeat={"no-repeat"}
      width="100%"
      height={"100%"}
      backgroundSize={"cover"}
      backgroundPosition={"center"}
    >
      <Box height={"100%"}>
        {!approved ? (
          <>
            <HeadingTitle
              text={
                type === "solo"
                  ? "Solo portrait"
                  : type === "couple"
                  ? "Couple portrait"
                  : "Custom portrait"
              }
            />
            <Formik
              innerRef={formRef}
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                image: "",
                customBgInfo: "",
                customBackground: false,
                type: type,
                orderID: "",
              }}
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .required("First name required")
                  .matches(
                    /^([^0-9]*)$/,
                    "Only alphabets are allowed for this field "
                  ),
                lastName: Yup.string()
                  .required("Last name required")
                  .matches(
                    /^([^0-9]*)$/,
                    "Only alphabets are allowed for this field "
                  ),
                email: Yup.string()
                  .email("Invalid email address")
                  .required("Email required"),
                image: Yup.mixed().required("A file is required"),
                customBgInfo: Yup.string().when("customBackground", {
                  is: (customBackground) => customBackground === true,
                  then: Yup.string().required("Background details required"),
                }),
              })}
            >
              {(formik) => (
                <VStack
                  as={"form"}
                  encType="multipart/form-data"
                  onSubmit={formik.handleSubmit}
                  mx="auto"
                  w={{ base: "90%", md: 600 }}
                  spacing={6}
                >
                  <TextField
                    name="firstName"
                    label="First Name*"
                    placeholder="Enter first name"
                  />
                  <TextField
                    name="lastName"
                    label="Last Name*"
                    placeholder="Enter last name"
                  />
                  <TextField
                    name="email"
                    label="Email*"
                    placeholder="Enter email"
                  />
                  <TextField
                    name="image"
                    accept="image/*"
                    label="Upload image*"
                    placeholder="Upload image"
                    type="file"
                    value={undefined}
                    pt={1}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "image",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    w={"100%"}
                  >
                    <Flex>
                      <Tooltip label="+10€">
                        <Flex alignItems={"center"}>
                          <BiInfoCircle cursor={"pointer"} />
                          <Text pl={1} pr={4}>
                            Custom background
                          </Text>
                        </Flex>
                      </Tooltip>
                      <Field name="customBackground">
                        {({ field, form, meta }) => {
                          return (
                            <input
                              type="checkbox"
                              style={{ width: "20px" }}
                              checked={field.value}
                              onClick={() => {
                                if (!field.value) {
                                  setAmount(amount + 10);
                                } else {
                                  setAmount(amount - 10);
                                }
                              }}
                              {...field}
                            />
                          );
                        }}
                      </Field>
                    </Flex>
                    {type === "custom" && (
                      <Flex>
                        <Text pr={4}>Number of person to draw</Text>
                        <Field
                          as="select"
                          name="numberPerson"
                          style={{ background: "transparent" }}
                          onChange={(e) => {
                            setNumPerson(e.target.value);
                            if (formik.values.customBackground) {
                              setAmount(30 * e.target.value + 10);
                            } else {
                              setAmount(30 * e.target.value);
                            }
                          }}
                        >
                          {[3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
                            return (
                              <option
                                key={item}
                                style={{ background: "#060d11" }}
                                value={item}
                              >
                                {item}
                              </option>
                            );
                          })}
                        </Field>
                      </Flex>
                    )}
                  </Flex>
                  {formik.values.customBackground && (
                    <TextField
                      name="customBgInfo"
                      label="Add some details*"
                      placeholder="Add some details"
                    />
                  )}
                  <Box
                    borderColor={"gray"}
                    borderBottomWidth={"1px"}
                    borderStyle={"solid"}
                    w="100%"
                  />
                  <Flex w="100%" justifyContent={"flex-end"}>
                    Total: {amount}€
                  </Flex>
                  <Flex
                    w="100%"
                    alignItems={"center"}
                    bg="white"
                    borderRadius={3}
                    px={5}
                    py={5}
                  >
                    <PayPalScriptProvider
                      options={{
                        "client-id":
                          // "AVc7-m07kzN2e5RE_5qhJNgcVCneYKdx9GRtHjfCd2-BBCmHOckb-aLcGP8Bk-fnEbbeetAclhw5Vm7W",
                          "ATlSzgXzfnS7tea1psxHJpU_gf7fH4MxXy8dLUGMGxBIfPoMWu5TqQvy2tzmrXVS1Oi3SOrIV6_LNFgT",
                        components: "buttons",
                        currency: "EUR",
                      }}
                    >
                      <ButtonWrapper
                        w="100%"
                        currency={currency}
                        showSpinner={false}
                      />
                    </PayPalScriptProvider>
                  </Flex>
                  <Text fontSize={13} textAlign="left" w="100%">
                    * make sure to fill all the required fields
                  </Text>
                </VStack>
              )}
            </Formik>
            <Container maxW={"1500px"}>
              <Box mt={32}>
                <Flex
                  w="100%"
                  justifyContent={"flex-end"}
                  px={{ base: 2, md: 14 }}
                  pb={4}
                >
                  <ButtonPrimary
                    variant="outline"
                    size={"sm"}
                    link="/gallery"
                    text="See full gallery"
                  />
                </Flex>
                <Slider {...settings}>
                  {portraits_slider.map((portrait, index) => {
                    return (
                      type === portrait.type && (
                        <PortraitCard
                          key={index}
                          imageName={portrait.image}
                          size={{ base: "95%", md: "90%" }}
                          folderName="portraits"
                        />
                      )
                    );
                  })}
                </Slider>
              </Box>
            </Container>
          </>
        ) : (
          <Flex
            w="100%"
            height={"70vh"}
            justifyContent={"center"}
            alignItems="center"
            flexDir={"column"}
            fontSize={18}
            data-aos="zoom-in"
          >
            <BsCheckCircleFill fontSize={60} color={"#eaa800"} />
            <HeadingTitle text="Thank you !" />
            <Text>
              Your order is being processed. A confirmation as been sent to your
              email.
            </Text>
            <Box as="div">
              Your order reference:{" "}
              <Box as="span" color={"primary.100"}>
                #{orderId}
              </Box>
            </Box>
            <Text pt={10} pb={4}>
              Since you're here, check the portraits gallery!
            </Text>
            <ButtonPrimary
              variant="outline"
              size="lg"
              text="Gallery"
              link={"/gallery"}
            />
          </Flex>
        )}
      </Box>
    </Box>
  );
}

export default FormOrder;
