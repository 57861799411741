import { Text, VStack } from "@chakra-ui/react";
import React from "react";
import HeaderScrollAnimation from "../components/homepage/HeaderScrollAnimation";

function Nfts() {
  return (
    <VStack mt={{ base: 10, lg: "headerTop.5" }}>
      <HeaderScrollAnimation />
      <Text display={{ base: "flex", md: "none" }} pt={10} textAlign={"center"}>
        Collection is coming soon..
      </Text>
      {/* <Flex flexDir={"column"}> */}
      {/* <CollectionNft imageName={"oxly_1.jpg"} /> */}
      {/* <NftCard /> */}
      {/* </Flex> */}
    </VStack>
  );
}

export default Nfts;
