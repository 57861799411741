import Home from "./pages/Home";
import Projects from "./pages/Projects";
import SingleProject from "./pages/SingleProject";
import { Route, useLocation } from "react-router-dom";
import { Switch } from "react-router-dom";
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";
import Contact from "./pages/Contact";
import LegalNotices from "./pages/LegalNotices";
import Nfts from "./pages/Nfts";
import PageNotFound from "./pages/PageNotFound";
import { Box, Container } from "@chakra-ui/react";
import OrderPortrait from "./pages/OrderPortrait";
import ScrollTop from "./components/ScrollTop";
import AOS from "aos";
import { useEffect } from "react";
import FormOrder from "./pages/FormOrder";
import Gallery from "./pages/Gallery";

function App() {
  const location = useLocation();
  useEffect(() => {
    AOS.init({ duration: 1500, once: true });
  }, []);
  return (
    <Box
      onContextMenu={(e) => {
        e.preventDefault();
        return false;
      }}
    >
      <Navbar />
      <Container
        p={
          location.pathname !== "/order-portrait/form" &&
          location.pathname !== "/" &&
          location.pathname !== "/nfts"
            ? "auto"
            : 0
        }
        maxW={
          location.pathname !== "/order-portrait/form" &&
          location.pathname !== "/" &&
          location.pathname !== "/nfts"
            ? "1500px"
            : "none"
        }
      >
        <ScrollTop>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/legal-notices" component={LegalNotices} />
            <Route exact path="/order-portrait" component={OrderPortrait} />
            <Route exact path="/order-portrait/form" component={FormOrder} />
            <Route exact path="/projects" component={Projects} />
            {/* <Route exact path="/projects/:slug" component={SingleProject} /> */}
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/nfts" component={Nfts} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </ScrollTop>
      </Container>

      <Footer />
    </Box>
  );
}

export default App;
