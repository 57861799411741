import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ReactPlayer from "react-player";
import HeadingTitle from "../common/HeadingTitle";

function HorizontalVideo({ title, previewImage, url }) {
  return (
    <Flex
      flexDir={"column"}
      alignItems="center"
      justifyContent={"center"}
      w="100%"
      data-aos="fade-up"
      py={{ base: 0, md: 10, lg: 10 }}
    >
      <HeadingTitle text={title} />
      <Flex
        justifyContent={"space-between"}
        alignItems="center"
        w="100%"
        onContextMenu={(e) => {
          e.preventDefault();
          return false;
        }}
      >
        <Box className="wrapper" pos="relative" w="100%">
          <ReactPlayer
            width={"100%"}
            height={"80vh"}
            light={`../images/preview_video/${previewImage}`}
            playing={true}
            loop={true}
            url={[url]}
            volume={0}
            controls
          />
        </Box>
      </Flex>
    </Flex>
  );
}

export default HorizontalVideo;
