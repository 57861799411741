import { Container, VStack } from "@chakra-ui/react";
import React from "react";
import Biography from "../components/homepage/Biography";
import HomeHeader from "../components/homepage/HomeHeader";
import Portraits from "../components/homepage/Portraits";
import News from "../components/projects/News";

function Home() {
  return (
    <VStack>
      <HomeHeader />
      <Biography />
      {/* <HeaderScrollAnimation /> */}
      <Container maxW={"1500px"}>
        <News order={3} title="Animation projects" />
        {/* <VideoProjects /> */}
        <Portraits />
      </Container>
    </VStack>
  );
}

export default Home;
