import { Button, Link } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import React from "react";

function ButtonPrimary(props) {
  return (
    <Link
      as={NavLink}
      to={props.isExternal ? { pathname: props.link } : props.link}
      _hover={{ textDecoration: "none" }}
      isExternal={props.isExternal}
    >
      <Button
        variant={props.variant}
        bg={props.variant === "solid" ? "primary.100" : "transparent"}
        borderColor={"primary.100"}
        textTransform="uppercase"
        fontWeight={"bold"}
        cursor={props.disabled ? "not-allowed" : "pointer"}
        px={6}
        textColor="white"
        size={{ base: "sm", md: "md", lg: props.size }}
        _hover={
          props.variant === "solid"
            ? { bg: "primary.200" }
            : { bg: "primary.100" }
        }
      >
        {props.text}
      </Button>
    </Link>
  );
}

export default ButtonPrimary;
