import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import React from "react";
// import { NavLink } from "react-router-dom";

function Card({ imageName, title, size, folderName, type, link }) {
  return (
    <Box
      // as={type === "news" ? NavLink : ""}
      // to={type === "news" ? link : ""}
      borderRadius={"20px"}
      padding={"1px"}
      data-aos="flip-right"
      width={size}
      cursor={type === "news" ? "pointer" : "pointer-events-none"}
    >
      <Image
        src={`/images/${folderName}/${imageName}`}
        borderRadius={5}
        w={"100%"}
      />
      <Flex
        alignItems="center"
        justifyContent="center"
        padding={4}
        flexDirection={"column"}
      >
        {title && (
          <Heading as={"h4"} size={{ base: "sm", md: "md" }} textAlign="center">
            {title}
          </Heading>
        )}
      </Flex>
    </Box>
  );
}

export default Card;
