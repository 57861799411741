import {
  Box,
  Button,
  Image,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { FaFileUpload, FaPencilAlt } from "react-icons/fa";
import { MdOutlinePayment, MdMarkEmailRead } from "react-icons/md";
import HeadingTitle from "./common/HeadingTitle";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

function Timeline() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <HeadingTitle text="What is the process" />
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            border: "solid",
            borderColor: "#c78f00",
            color: "#fff",
            background: "transparent",
          }}
          contentArrowStyle={{ borderRight: "7px solid  #c78f00" }}
          date="Step 1"
          iconStyle={{ background: "#eaa800", color: "#fff" }}
          icon={<FaFileUpload />}
        >
          <h3 className="vertical-timeline-element-title">
            Upload the picture
          </h3>
          <p>
            A good quality picture is needed for the drawing process. The best
            quality is the image, the more details you'll get! (no animal
            drawing)
          </p>
          <Text
            cursor={"pointer"}
            color={"primary.100"}
            _hover={{ textDecor: "underline" }}
            onClick={onOpen}
          >
            See more details
          </Text>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Step 2"
          contentStyle={{
            border: "solid",
            borderColor: "#c78f00",
            color: "#fff",
            background: "transparent",
          }}
          contentArrowStyle={{ borderRight: "7px solid  #c78f00" }}
          iconStyle={{ background: "#eaa800", color: "#fff" }}
          icon={<MdOutlinePayment />}
        >
          <h3 className="vertical-timeline-element-title">Payment</h3>
          <p>
            All payments must be done via paypal or credit card before the start
            of drawing.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Step 3"
          contentStyle={{
            border: "solid",
            borderColor: "#c78f00",
            color: "#fff",
            background: "transparent",
          }}
          contentArrowStyle={{ borderRight: "7px solid  #c78f00" }}
          iconStyle={{ background: "#eaa800", color: "#fff" }}
          icon={<FaPencilAlt />}
        >
          <h3 className="vertical-timeline-element-title">Start drawing</h3>
          <p>
            Here we go! The drawing starts, you just have to be patient now ! If
            you DM me on{" "}
            <Link
              href={"https://www.facebook.com/freddynovation.portrait/"}
              isExternal
              color={"primary.100"}
              _hover={{ textDecor: "underline" }}
            >
              Facebook
            </Link>{" "}
            or{" "}
            <Link
              href={"https://www.instagram.com/freddynovation/"}
              isExternal
              color={"primary.100"}
              _hover={{ textDecor: "underline" }}
            >
              Instagram
            </Link>
            , I can send you some sneak peaks.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          date="Step 4"
          iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
          contentStyle={{
            border: "solid",
            borderColor: "#c78f00",
            color: "#fff",
            background: "transparent",
          }}
          contentArrowStyle={{ borderRight: "7px solid  #c78f00" }}
          icon={<MdMarkEmailRead />}
        >
          <h3 className="vertical-timeline-element-title">It's done !</h3>
          <p>Check your email and enjoy your cartoon portrait !</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
      {/* MODAL */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"lg"}>
        <ModalOverlay />
        <ModalContent background={"body.main"}>
          <ModalHeader>Image rule</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box w={"100%"}>
              <Image src={`/images/others/image-rule-2.webp`} w="100%" />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button bg="primary.100" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Timeline;
