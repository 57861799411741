import { Container, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import {
  AiOutlineYoutube,
  AiOutlineInstagram,
  AiOutlineFacebook,
} from "react-icons/ai";
import { TbBrandTiktok } from "react-icons/tb";
import { NavLink } from "react-router-dom";

function Footer() {
  const socials = [
    {
      icon: AiOutlineInstagram,
      link: "https://www.instagram.com/freddynovation/",
    },
    { icon: AiOutlineYoutube, link: "https://youtube.com/freddynovation/" },
    {
      icon: AiOutlineFacebook,
      link: "https://www.facebook.com/FreddyNovation/",
    },
    {
      icon: TbBrandTiktok,
      link: "https://www.tiktok.com/@freddynovationvfx",
    },
  ];
  return (
    <Container maxW={"1500px"}>
      <Flex
        justifyContent={"center"}
        alignItems="center"
        flexDirection={"column"}
        w="100%"
        py={10}
      >
        <Flex alignItems={"center"} py={10} justifyContent="space-between">
          {socials.map((social, index) => {
            return (
              <Link
                key={index}
                px={4}
                href={social.link}
                isExternal
                _hover={{ color: "primary.100" }}
              >
                <social.icon size={52} _hover={{ size: 60 }} />
              </Link>
            );
          })}
        </Flex>
        <Flex mb={4}>
          <Link as={NavLink} to={"/legal-notices"} _hover={{ color: "primary.100" }}>
            <Flex alignItems={"center"}>
              <Text pr={4}>Legal Notices</Text>
            </Flex>
          </Link>
        </Flex>
        <Text textAlign={"center"}>
          All rights reserved. © 2022 freddynovation.com
        </Text>
      </Flex>
    </Container>
  );
}

export default Footer;
