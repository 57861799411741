import {
  Flex,
  HStack,
  VStack,
  IconButton,
  Image,
  Link,
  Text,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ButtonPrimary from "../ButtonPrimary";
import { HiMenu } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";

function Navbar() {
  const location = useLocation();
  const menuItems = [
    { name: "Projects", link: "/projects" },
    { name: "NFTs", link: "/nfts" },
    { name: "Contact", link: "/contact" },
  ];
  const [colorChange, setColorchange] = useState(false);
  const [display, changeDisplay] = useState("none");
  const changeNavbarColor = () => {
    window.scrollY >= 20 ? setColorchange(true) : setColorchange(false);
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <Flex
      textColor="white"
      height="20"
      width="100%"
      alignItems="center"
      position="fixed"
      zIndex={"9999"}
      top={0}
      px={{ base: 4, md: 10, lg: 10, xl: 56 }}
      transition={"0.3s all ease"}
      bg={
        (colorChange &&
          (location.pathname === "/" || location.pathname === "/nfts")) ||
        location.pathname !== "/"
          ? "body.main"
          : "transparent"
      }
    >
      <Flex
        w={"100%"}
        className="menu-items"
        justifyContent="space-between"
        alignItems="center"
        display={["none", "none", "none", "flex", "flex"]}
      >
        <Link as={NavLink} to={"/"} w={46} _hover={{ textDecoration: "none" }}>
          <Flex alignItems={"center"}>
            <Image src={`/images/logo/freddynovation_logo_only.png`} />
            <Text pl={4}>freddynovation.com</Text>
          </Flex>
        </Link>
        <HStack spacing={8} alignItems={"center"} pl={20}>
          {menuItems.map((item) => {
            return (
              <Link
                fontSize={16}
                // textTransform={"uppercase"}
                key={item.link}
                activeStyle={{ borderBottom: "3px solid #eaa800" }}
                _hover={{ textDecoration: "none" }}
                as={NavLink}
                to={item.link}
              >
                {item.name}
              </Link>
            );
          })}
        </HStack>
        <ButtonPrimary
          text="Order portrait"
          variant="outline"
          size="sm"
          link="/order-portrait"
        />
      </Flex>
      <Flex
        justifyContent={"space-between"}
        w="100%"
        display={["flex", "flex", "flex", "none", "none"]}
      >
        <Link as={NavLink} to={"/"} w={46} _hover={{ textDecoration: "none" }}>
          <Flex alignItems={"center"}>
            <Image src={`/images/logo/freddynovation_logo_only.png`} />
          </Flex>
        </Link>
        <IconButton
          aria-label="Open Menu"
          size={"lg"}
          icon={<HiMenu />}
          bg="primary.100"
          _hover={{ bg: "primary.200" }}
          onClick={() => changeDisplay("flex")}
        />
      </Flex>
      <Flex
        w={"100%"}
        h={"100vh"}
        zIndex="99"
        bgColor={"body.main"}
        pos="fixed"
        top={0}
        left={0}
        overflowY="auto"
        flexDir={"column"}
        display={display}
      >
        <Flex justify={"flex-end"}>
          <IconButton
            mt={2}
            mr={2}
            aria-label="Close Menu"
            size={"lg"}
            bg="primary.100"
            _hover={{ bg: "primary.200" }}
            icon={<MdOutlineClose />}
            onClick={() => changeDisplay("none")}
          />
        </Flex>
        <Flex flexDir={"column"} align="center" mt={2}>
          <Link
            as={NavLink}
            to={"/"}
            w={10}
            _hover={{ textDecoration: "none" }}
            mb={6}
            onClick={() => changeDisplay("none")}
          >
            <Image src={`/images/logo/freddynovation_logo_only.png`} />
          </Link>
          <VStack alignItems={"center"} spacing={6} mb={6}>
            {menuItems.map((item) => {
              return (
                <Link
                  fontSize={16}
                  // textTransform={"uppercase"}
                  key={item.link}
                  activeStyle={{ borderBottom: "3px solid #eaa800" }}
                  _hover={{ textDecoration: "none" }}
                  as={NavLink}
                  to={item.link}
                  onClick={() => changeDisplay("none")}
                >
                  {item.name}
                </Link>
              );
            })}
          </VStack>
          <Box onClick={() => changeDisplay("none")}>
            <ButtonPrimary
              text="Order portrait"
              variant="outline"
              size="sm"
              link="/order-portrait"
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Navbar;
