import { extendTheme } from "@chakra-ui/react";
import "@fontsource/montserrat";
import "@fontsource/raleway";

const theme = extendTheme({
  fonts: {
    heading: `Montserrat`,
    body: `Montserrat`,
  },
  fontWeights: {
    bold: 700,
  },
  space: {
    headerTop: {
      5: "5rem",
      10: "10rem",
    },
  },
  colors: {
    body: {
      main: "#060d11",
      secondary: "#7EA1B3",
      white: "#fff",
      nav: "#1b1b1b",
      disabled: "#EBEBE4",
    },
    primary: {
      100: "#eaa800",
      200: "#c78f00",
      300: "#ffecc9",
    },
  },
  styles: {
    global: () => ({
      body: {
        bgColor: "body.main",
        textColor: "white",
        overflowX: "hidden",
      },
      h2: {
        padding: "3rem 0",
        fontSize: { base: "32px", md: "40px", lg: "64px" },
        fontWeight: "bold",
      },
      h3: {
        padding: "1rem 0",
        fontSize: { base: "12px", md: "24px", lg: "40px" },
        fontWeight: "bold",
      },
    }),
  },
});

export default theme;
