import { Box, Button, Flex, Text, VStack } from "@chakra-ui/react";
import { Formik } from "formik";
import HeadingTitle from "../components/common/HeadingTitle";
import * as Yup from "yup";
import TextField from "../components/form/TextField";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import ButtonPrimary from "../components/ButtonPrimary";

function Contact() {
  const [isValid, setIsValid] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  return (
    <VStack w={"100%"} mt={{ base: 20, lg: "headerTop.5" }}>
      {!emailSent ? (
        <>
          <HeadingTitle text={"Contact me"} />
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              message: "",
            }}
            validationSchema={Yup.object({
              firstName: Yup.string()
                .required("First name required")
                .matches(
                  /^([^0-9]*)$/,
                  "Only alphabets are allowed for this field"
                ),
              lastName: Yup.string()
                .required("Last name required")
                .matches(
                  /^([^0-9]*)$/,
                  "Only alphabets are allowed for this field"
                ),
              email: Yup.string()
                .email("Invalid email address")
                .required("Email required"),
              message: Yup.string().required("Message required"),
            })}
          >
            {(formik) => (
              <VStack
                as={"form"}
                onSubmit={formik.handleSubmit}
                mx="auto"
                w={{ base: "90%", md: 600 }}
                spacing={6}
              >
                <TextField
                  name="firstName"
                  label="First Name*"
                  placeholder="Enter first name"
                />
                <TextField
                  name="lastName"
                  label="Last Name*"
                  placeholder="Enter last name"
                />
                <TextField
                  name="email"
                  label="Email*"
                  placeholder="Enter email"
                />
                <TextField
                  as="textarea"
                  name="message"
                  label="Message*"
                  placeholder="Enter message here..."
                  style={{
                    border:
                      !formik.values.message && formik.touched.message
                        ? "1px solid red"
                        : "1px solid white",
                    width: "100%",
                    background: "transparent",
                    borderRadius: "5px",
                    padding: "15px",
                  }}
                />
                <ReCAPTCHA
                  sitekey="6Lc2vKEhAAAAAIHJUCW0JGSiZ8JW2au9lBsRcu2n"
                  onChange={() => setIsValid(true)}
                />
                ,
                <Button
                  pointerEvents={
                    isValid && formik.isValid && formik.dirty ? "auto" : "none"
                  }
                  bg={
                    isValid && formik.isValid && formik.dirty
                      ? "primary.100"
                      : "body.disabled"
                  }
                  color={
                    isValid && formik.isValid && formik.dirty
                      ? "white"
                      : "lightgray"
                  }
                  onClick={() => {
                    const formData = new FormData();
                    formData.append("firstName", formik.values.firstName);
                    formData.append("lastName", formik.values.lastName);
                    formData.append("email", formik.values.email);
                    formData.append("message", formik.values.message);
                    axios
                      .post(
                        "https://freddynovation-api.freddynovation.com/contact.php",
                        formData
                      )
                      .then((response) => {
                        console.log(response);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                    setEmailSent(true);
                    setIsValid(false);
                    formik.resetForm();
                  }}
                >
                  SEND MESSAGE
                </Button>
              </VStack>
            )}
          </Formik>
        </>
      ) : (
        <Flex
          w="100%"
          height={{ base: "100%", md: "70vh" }}
          justifyContent={"center"}
          alignItems="center"
          flexDir={"column"}
          fontSize={18}
          data-aos="zoom-in"
          mt={{ base: 10, lg: 0 }}
        >
          <BsCheckCircleFill fontSize={60} color={"#eaa800"} />
          <HeadingTitle text="Thank you !" />
          <Text textAlign={"center"}>
            Your message has been successfully sent !
          </Text>
          <Box py={6}>
            <ButtonPrimary text="go back home" link="/" variant="solid" />
          </Box>
        </Flex>
      )}
    </VStack>
  );
}

export default Contact;
