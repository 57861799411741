// PHOTOS COMPONENT FOR THE MOSAIC
const breakpoints = [2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (id) => `./images/portraits/${id}`;

const unsplashPhotos = [
  { id: "bionic.png", width: 612, height: 792 },
  { id: "aashanaka_FINAL.webp", width: 1280, height: 1600 },
  { id: "celeste.webp", width: 1280, height: 1600 },
  { id: "ef-01.png", width: 612, height: 792 },
  { id: "fastgoodcuisine.webp", width: 612, height: 792 },
  { id: "gabrielLevan.webp", width: 612, height: 792 },
  { id: "Lenamahfouf.webp", width: 1080, height: 1280 },
  { id: "loris_g_v2.webp", width: 1080, height: 1400 },
  { id: "lowik.webp", width: 1080, height: 1500 },
  { id: "lennavivas.webp", width: 1280, height: 1600 },
  { id: "mattdoesfitness.webp", width: 1400, height: 1920 },
  { id: "nathan_mozango.png", width: 1280, height: 1800 },
  { id: "anirudh.webp", width: 1280, height: 1600 },
  { id: "drake.webp", width: 1080, height: 1400 },
  { id: "nikki-01.png", width: 1080, height: 1280 },
  { id: "saintlion.webp", width: 1080, height: 1280 },
  { id: "nivethaPethuraj.png", width: 1600, height: 1920 },
  { id: "bernard_fam.webp", width: 1920, height: 1200 },
  { id: "panda_mita.webp", width: 1080, height: 1400 },
  { id: "priyabascarinFinal2.webp", width: 1080, height: 1280 },
  { id: "boris&fuckay.webp", width: 1080, height: 1280 },
  { id: "ramzan_jemb.webp", width: 1080, height: 1280 },
  { id: "laura.webp", width: 1080, height: 1400 },
  { id: "ritchie_golo.png", width: 1280, height: 1900 },
  { id: "ritika.webp", width: 1400, height: 1920 },
  { id: "mhd.webp", width: 1400, height: 1920 },
  { id: "sab_friend.webp", width: 1080, height: 1280 },
  { id: "sabrina.webp", width: 1080, height: 1400 },
  { id: "sophia_akkara.png", width: 1080, height: 1500 },
  { id: "larry.webp", width: 1080, height: 1300 },
  { id: "yedess.png", width: 1080, height: 1500 },
  { id: "stevenkao.webp", width: 1080, height: 1280 },
  { id: "taissir3.webp", width: 1280, height: 1600 },
  { id: "tiboInshape.png", width: 1080, height: 1400 },
  { id: "khaby_achraf.webp", width: 1080, height: 1400 },
  { id: "Vijay_customer.webp", width: 1080, height: 1280 },
  { id: "villu_vethi.webp", width: 1080, height: 1400 },
];

const photos = unsplashPhotos.map((photo) => ({
  src: unsplashLink(photo.id, photo.width, photo.height),
  width: photo.width,
  height: photo.height,
  images: breakpoints.map((breakpoint) => {
    const height = Math.round((photo.height / photo.width) * breakpoint);
    return {
      src: unsplashLink(photo.id, breakpoint, height),
      width: breakpoint,
      height,
    };
  }),
}));

export default photos;
