import { Flex, Text, VStack } from "@chakra-ui/react";
import HeadingTitle from "../components/common/HeadingTitle";
import React from "react";

function LegalNotices() {
  return (
    <VStack mt={{ base: 10, lg: "headerTop.5" }}>
      <HeadingTitle text={"Legal notices"} />
      <VStack spacing={10} align="flex-start">
        <Flex flexDir={"column"}>
          <Text fontSize={24} fontWeight="bold" mb={4}>Website owner :</Text>
          <Text>This website is owned by freddynovation, contact@freddynovation.com</Text>
        </Flex>
        <Flex flexDir={"column"}>
          <Text fontSize={24} fontWeight="bold" mb={4}>Content :</Text>
          <Text> All content on this website, including but not limited to videos, drawings, and other materials, is the property of freddynovation and is protected by copyright law. Any unauthorized use or reproduction of the content is strictly prohibited.</Text>
        </Flex>
        <Flex flexDir={"column"}>
          <Text fontSize={24} fontWeight="bold" mb={4}>Disclaimer :</Text>
          <Text>The information on this website is provided for general informational purposes only. freddynovation makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</Text>
        </Flex>
        <Flex flexDir={"column"}>
          <Text fontSize={24} fontWeight="bold" mb={4}>Links to other websites :</Text>
          <Text>This website may contain links to other websites that are not owned or controlled by freddynovation. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites.</Text>
        </Flex>
        <Flex flexDir={"column"}>
          <Text fontSize={24} fontWeight="bold" mb={4}>Contact :</Text>
          <Text>If you have any questions or concerns about these legal notices, please contact me at contact@freddynovation.com.</Text>
        </Flex>
      </VStack>
      <Text>
      
      

      
      
      </Text>
    </VStack>
  );
}

export default LegalNotices;
